import React from "react";
import Modal from 'react-modal'
import * as styles from './popoverItem.module.scss';
import { PortableText } from '@portabletext/react';

const modalStyles = {
    content: {
      top: '140px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      paddingTop: '5px',
      marginRight: '-50%',
      paddingBottom: '140px',
      transform: 'translatex(-50%)',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 0,
      padding: 0,
      overflow: 'visible',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0, 0.8)',
    },
}

const PopoverItem = (props) => {
    const { appContainerRef, selectItem, data } = props;

    Modal.setAppElement(appContainerRef);
    if(data === null) {
        return (<></>);
      }
    // console.log(data);
    let themeClass = '';
    // switch(themeContext) {
    //     case 'appliedSciences':
    //         themeClass = styles.appSci;
    //         break;
    //     case 'stansonHealth':
    //         themeClass = styles.stanson;
    //         break;
    //     case 'Clinical Intelligence':
    //         themeClass = styles.clinical;
    //         break;
    //     case 'Value-Based Care':
    //         themeClass = styles.value;
    //         break;
    //     case 'Margin Improvement':
    //         themeClass = styles.marginImp;
    //         break;
    //     default:
    //         themeClass = styles.appSci;
    //         break;
    // }

    return (
        <>
        <Modal
            closeTimeoutMS={200} // needs to match css transition duration
            isOpen={data !== null}
            onRequestClose={() => selectItem(null)}
            style={modalStyles}
            onAfterOpen={() => (document.body.style.overflow = 'hidden')}
            onAfterClose={() => (document.body.style.overflow = 'unset')}
        >
        <div className={styles.itemContainer}>
            <div className={styles.item + " " + themeClass + " " + (data.image ? styles.imageSpacing : styles.noImage)}>
                {data.image && 
                    <div className={styles.itemImageContainer}>
                        <img src={data.image.asset.url} alt={data.altText || 'Product Image'}/>
                    </div>
                }
                <div>
                    {data.title &&
                        <div className={styles.titleContainer}>
                            <p>{data.title}</p>
                        </div>
                    }
                    {data.subtitle &&
                        <div className={styles.subtitleContainer}>
                            <p>{data.subtitle}</p>
                        </div>
                    }
                    {data._rawDescription &&
                        <div className={styles.descriptionContainer}>
                            <PortableText value={data._rawDescription}/>
                        </div>
                    }
                </div>
            </div>
        {/* close icon */}
        <button
          type="button"
          className={`${styles.modalclosebutton}`}
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => props.selectItem(null)}
        >
          <svg
            fill="none"
            height="44"
            viewBox="0 0 44 44"
            width="44"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="#616161" strokeWidth="2">
              <circle cx="22" cy="22" fill="#000" r="21" />
              <path d="m15.7174 15.7175 13.435 13.435" />
              <path d="m15.7177 29.1525 13.435-13.435" />
            </g>
          </svg>
        </button>
      </div>
    </Modal>
        </>
    )
}

export default PopoverItem