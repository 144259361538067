// extracted by mini-css-extract-plugin
export var accordionContainer = "ProductAccordion-module--accordionContainer--e717c";
export var accordionDescription = "ProductAccordion-module--accordionDescription--60ca9";
export var accordionImageContainer = "ProductAccordion-module--accordionImageContainer--eb81e";
export var accordionInfoContainer = "ProductAccordion-module--accordionInfoContainer--a929e";
export var accordionTitle = "ProductAccordion-module--accordionTitle--6af97";
export var centerArticle = "ProductAccordion-module--centerArticle--d055e";
export var clinical = "ProductAccordion-module--clinical--c549b";
export var falseButton = "ProductAccordion-module--falseButton--5ae65";
export var itemDescription = "ProductAccordion-module--itemDescription--b0e25";
export var itemTitle = "ProductAccordion-module--itemTitle--fdcbd";
export var itemTitleSymbol = "ProductAccordion-module--itemTitleSymbol--2914b";
export var leftArticle = "ProductAccordion-module--leftArticle--41e44";
export var line = "ProductAccordion-module--line--afed5";
export var marginImp = "ProductAccordion-module--marginImp--e319b";
export var mediaContainer = "ProductAccordion-module--mediaContainer--bb74f";
export var mediaImage = "ProductAccordion-module--mediaImage--d3f97";
export var mediaImageContainer = "ProductAccordion-module--mediaImageContainer--2a639";
export var open = "ProductAccordion-module--open--b5fcf";
export var rightArticle = "ProductAccordion-module--rightArticle--ac733";
export var value = "ProductAccordion-module--value--6120a";