import React, {useState, useLayoutEffect, useRef} from 'react'
import * as styles from './ProductAccordion.module.scss'
import { gsap } from "gsap";
import { PortableText } from '@portabletext/react';
import VideoEmbed from '../VideoEmbed/VideoEmbed';

const AccordionItem = (props) => {

  const {item, theme} = props;
  // console.log(theme);
  const descriptionRef = useRef()

  let style = {color: "#DA437E"};
  let color = "#DA734E";
  let colorTheme = styles.clinical;
  if(theme) {
    style = { color: theme.settings.primaryColor };
    color = theme.settings.primaryColor;
    switch(theme.solutionTitle) {
      case 'Clinical Intelligence':
        colorTheme = styles.clinical;
        break;
      case 'Margin Improvement':
        colorTheme = styles.marginImp;
        break;
      case 'Value Based Care':
        colorTheme = styles.value;
        break;
      default:
        colorTheme = styles.clinical;
        break;
    }
  }

  const [isExpanded, setIsExpanded] = useState();

  const itemDescriptionClassNames = `${styles.itemDescription} ${colorTheme}`

  const itemTitleSymbolClassNames = `${styles.itemTitleSymbol} ${isExpanded ? styles.open : ''} `

  const customComponents = {
    marks: {
        footnote: ({children}) => <span><sup>{children}</sup></span>
    }
}

  let alignment = 'leftArticle';
  switch(item.alignment) {
    case 'center':
        alignment = 'centerArticle'
        break;
    case 'left':
        alignment = 'leftArticle'
        break;
    case 'right':
        alignment = 'rightArticle'
        break;
    default:
        alignment = 'leftArticle'
        break;
}
// console.log(alignment);

  useLayoutEffect(() => {
    if (isExpanded) {
      gsap.to(descriptionRef.current, {height: 'auto', opacity: '1'})
    }
    if (!isExpanded) {
      gsap.to(descriptionRef.current, {height: '0', opacity: '0'})
    }
  }, [isExpanded]);

  return (
    <div>
      <div>
        <button
          className={styles.falseButton}
          onClick={() => setIsExpanded((state) => !state)}
        >
          <h1 className={styles.itemTitle} style={style}>
            {item.itemTitle}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              fill="none"
              viewBox="0 0 13 13"
              className={itemTitleSymbolClassNames}
            >
              <path stroke={color} strokeWidth="2" d="M6.5 0L6.5 13"></path>
              <path stroke={color} strokeWidth="2" d="M0 6.5L13 6.5"></path>
            </svg>
          </h1>
        </button>
      </div>
      <div className={itemDescriptionClassNames + " " + styles[alignment]} ref={descriptionRef}>
        <PortableText value={item._rawItemDescription} components={customComponents} />
      </div>
    </div>
  )

}

const ProductAccordion = props => {
    const {data, theme} = props;
    let mediaRender = '';
    if(data?.headerVideo !== null) {
        mediaRender = <div className={styles.mediaContainer}>
                        <div 
                        // className={styles.videoContainer}
                        >
                          <VideoEmbed videoSrcURL={data?.headerVideo} videoTitle={data?.title}/>
                        </div>
                      </div>;
      } else if(data?.headerImage !== null) {
        mediaRender = <div className={styles.mediaContainer}>
                        <div className={styles.mediaImageContainer}>
                          <img src={data?.headerImage.asset.url} alt={data?.title}></img>
                        </div>
                      </div>;
      } else {
        mediaRender = <div></div>;
      }
    return (
        <>
        <div className={styles.accordionContainer}>
          { mediaRender }
                    <div className={styles.accordionInfoContainer} style={{borderLeft: `1px solid ${theme.settings.primaryColor}`}}>
                      <h1 className={styles.accordionTitle}>
                        {data?.sectionTitle}
                      </h1>
                      {data._rawSectionDescription &&
                        <div className={styles.accordionDescription}>
                          <PortableText value={data._rawSectionDescription}/>
                        </div>
                      }
                      <p className={styles.accordionDescription}>
                        {data?.sectionDescription}
                      </p>
                      <hr className={styles.line} />
                      {data.accordionItems.map((item, index) => {
                        return (
                          <AccordionItem key={index} item={item} theme={theme}/>
                        )
                      })}
                    </div>
                </div>
        </>
    )
}

export default ProductAccordion