import { PortableText } from "@portabletext/react";
import React from "react";
import VideoEmbed from "../VideoEmbed/VideoEmbed";
import * as styles from './ProductMediaBlock.module.scss';

const ProductMediaBlock = (props) => {
    const {data} = props;

    const customComponents = {
        marks: {
            footnote: ({children}) => <span><sup>{children}</sup></span>
        }
    }

    let mediaRender = '';
    if(data?.video !== null) {
        mediaRender = <div className={styles.mediaContainer}>
                        <div 
                        // className={styles.videoContainer}
                        >
                          <VideoEmbed videoSrcURL={data?.video} videoTitle={data?.title}/>
                        </div>
                      </div>;
      } else if(data?.image !== null) {
        mediaRender = <div className={styles.mediaImageContainer}>
                        <img src={data.image.asset.url} alt="" />
                      </div>;
      } else {
        mediaRender = <div></div>;
      }

    return (
    <>
    <div className={styles.mediaBlockContainer}>
        <div className={styles.textContainer}>
            <h2>{data.title}</h2>
            <div className={styles.descriptionContainer}>
                <PortableText value={data._rawDescription} components={customComponents} />
            </div>
        </div>

    { mediaRender }
    </div>
    </>
    )
}

export default ProductMediaBlock