import React, { useRef, useEffect, useState } from "react";
import * as styles from './itemPopoverSection.module.scss';
import PopoverItem from "./popoverItem/popoverItem";
import { PortableText } from "@portabletext/react";

const ItemPopoverSection = (props) => {
    const { data, themeContext = 'appliedSciences' } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const containerRef = useRef(null);
    let themeClass = styles.appSci;
    switch(themeContext) {
        case 'appliedSciences':
            themeClass = styles.appSci;
            break;
        case 'stansonHealth':
            themeClass = styles.stanson;
            break;
        case 'Clinical Intelligence':
            themeClass = styles.clinical;
            break;
        case 'Value-Based Care':
            themeClass = styles.value;
            break;
        case 'Margin Improvement':
            themeClass = styles.marginImp;
            break;
        default:
            themeClass = styles.appSci;
            break;
    }

    const selectItem = (item) => {
        setSelectedItem(item);
    }
    const handleKeyDown = (item, event) => {
        if(event.key === 'Enter') {
            selectItem(item);
        }
    }
    useEffect(() => {

    }, [selectedItem]);

    return (
        <div ref={containerRef}>
        <PopoverItem
            appContainerRef={containerRef.current}
            item={selectedItem}
            selectItem={selectItem}
            data={selectedItem}
            themeContext={themeContext}
            />
            {data.title &&
                <div className={styles.itemPopoverTitleContainer}>
                    <p>{data.title}</p>
                </div>
            }
            {data._rawDescription &&
                <div className={styles.itemPopoverDescriptionContainer}>
                    <PortableText value={data._rawDescription}/>
                </div>
            }
            <div className={styles.itemListContainer + " " + themeClass}>
            <div className={styles.itemList}>
                    {data.items.map((item, index) => (
                        item.showElement &&
                        <div className={styles.itemCard} key={index} onClick={(e) => selectItem(item, e)} onKeyDown={(e) => handleKeyDown(item, e)} role="button" tabIndex={0}>
                        {item.image &&
                            <div className={styles.imageContainer}>
                                <img src={item.image?.asset.url} alt={item.altText} />
                            </div>
                        }
                        <div className={styles.textContainer}>
                            {item.title &&
                                <div className={styles.titleContainer}>
                                    <p>{item.title}</p>
                                </div>
                            }
                            {item.subtitle &&
                                <div className={styles.subtitleContainer}>
                                    <p>{item.subtitle}</p>
                                </div>
                            }
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ItemPopoverSection