// extracted by mini-css-extract-plugin
export var container = "popoverItem-module--container--d0427";
export var descriptionContainer = "popoverItem-module--descriptionContainer--331ab";
export var imageSpacing = "popoverItem-module--imageSpacing--78691";
export var item = "popoverItem-module--item--42e67";
export var itemContainer = "popoverItem-module--itemContainer--6dc5d";
export var itemImageContainer = "popoverItem-module--itemImageContainer--58b65";
export var memberDegreeContainer = "popoverItem-module--memberDegreeContainer--c9152";
export var modalclosebutton = "popoverItem-module--modalclosebutton--4d85f";
export var noImage = "popoverItem-module--noImage--fb3b0";
export var subtitleContainer = "popoverItem-module--subtitleContainer--50b97";
export var title = "popoverItem-module--title--1df9d";
export var titleContainer = "popoverItem-module--titleContainer--93db2";