import React, { useState, useRef } from 'react'
import * as styles from './ProductCTA.module.scss'
import MarketoPopup from '../MarketoPopup/MarketoPopup';
import ButtonTemplate from '../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate';

const ProductCTA = props => {
    const {data, theme} = props;
    const containerRef = useRef(null)
    const [formIsOpen, setFormIsOpen] = useState(false);
    let themeStyling = '';
    
    switch(theme.solutionTitle) {
      case 'Clinical Intelligence':
        themeStyling = styles.clinical;
        break;
      case 'Value-Based Care':
        themeStyling = styles.value;
        break;
      case 'Margin Improvement':
        themeStyling = styles.margin
        break;
      default:
        break;
    }

    return(
      <>
        <div className={styles.ctaContainer} ref={containerRef}>
          <h1 className={styles.ctaTitle}>
            {data?.title}
          </h1>
          <p className={styles.ctaDescription}>
            {data?.description}
          </p>
          {data?.buttonName &&
            <div className={styles.ctaButtonContainer + " " + themeStyling}>
              <ButtonTemplate data={data?.buttonName}>
                {data?.buttonName?.buttonText}
              </ButtonTemplate>
            </div>
          }
        </div>
        <MarketoPopup
          appContainerRef={containerRef.current}
          isOpen={formIsOpen}
          setIsOpen={setFormIsOpen}
          category={data?.solutionCategory}
        />
      </>
    )
}

export default ProductCTA