// extracted by mini-css-extract-plugin
export var appSci = "itemPopoverSection-module--appSci--953b3";
export var clinical = "itemPopoverSection-module--clinical--e9393";
export var imageContainer = "itemPopoverSection-module--imageContainer--59b3d";
export var itemCard = "itemPopoverSection-module--itemCard--bd5d7";
export var itemList = "itemPopoverSection-module--itemList--f9cb4";
export var itemListContainer = "itemPopoverSection-module--itemListContainer--6a70d";
export var itemPopoverDescriptionContainer = "itemPopoverSection-module--itemPopoverDescriptionContainer--a7c1e";
export var itemPopoverTitleContainer = "itemPopoverSection-module--itemPopoverTitleContainer--4af65";
export var marginImp = "itemPopoverSection-module--marginImp--a5a59";
export var stanson = "itemPopoverSection-module--stanson--01dea";
export var subtitleContainer = "itemPopoverSection-module--subtitleContainer--561e0";
export var textContainer = "itemPopoverSection-module--textContainer--fd03a";
export var titleContainer = "itemPopoverSection-module--titleContainer--82ff6";
export var value = "itemPopoverSection-module--value--bada8";