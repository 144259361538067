// extracted by mini-css-extract-plugin
export var clinicalInt = "ProductMultiSection-module--clinicalInt--7b670";
export var downArrow = "ProductMultiSection-module--downArrow--afe88";
export var imageContainer = "ProductMultiSection-module--imageContainer--42451";
export var marginImprov = "ProductMultiSection-module--marginImprov--05be1";
export var multiSectionContainer = "ProductMultiSection-module--multiSectionContainer--2df68";
export var sectionContainer = "ProductMultiSection-module--sectionContainer--9edff";
export var showArrow = "ProductMultiSection-module--showArrow--0dd42";
export var solutionBlock = "ProductMultiSection-module--solutionBlock--dcdab";
export var solutionExpanded = "ProductMultiSection-module--solutionExpanded--77cb6";
export var solutionTextContainer = "ProductMultiSection-module--solutionTextContainer--6cc22";
export var stacked = "ProductMultiSection-module--stacked--f3f70";
export var textContainer = "ProductMultiSection-module--textContainer--a9cc2";
export var valueBasedCare = "ProductMultiSection-module--valueBasedCare--55fa4";