import React, { useState, useRef, useLayoutEffect } from 'react'
import * as styles from './ProductMultiSection.module.scss'
import ClinicalIntelligenceIcon from '../svg/ClinicalIntelligenceIcon'
import ValueBasedCareIcon from '../svg/ValueBasedCareIcon'
import MarginImprovementIcon from '../svg/MarginImprovementIcon'
import { PortableText } from '@portabletext/react'
import useMediaQuery from '../../utils/useMediaQuery'
import { gsap } from 'gsap'

const TextContent = (props) => {
  const { solution, index, activeTab, customComponents } = props
  const ref = useRef(null)

  useLayoutEffect(() => {
    // media query
    let mm = gsap.matchMedia()
    // let tween1
    // let tween2

    // desktop only - fade in/out text content
    mm.add('(min-width: 1001px)', () => {
      if (activeTab === index) {
        // tween1 = 
        gsap.fromTo(
          ref.current,
          {
            opacity: 0,
            zIndex: 2,
          },
          {
            duration: 0.5,
            opacity: 1,
            ease: 'power4.out',
            zIndex: 2,
          }
        )
      }
      if (activeTab !== index) {
        // tween2 = 
        gsap.to(ref.current, {
          duration: 0.5,
          opacity: 0,
          ease: 'power4.out',
          zIndex: -1,
        })
      }
    })

    return () => {
      // causing flash and do not see any memeory issues
      // mm.revert('(min-width: 1001px)')
      // tween1?.revert()
      // tween2?.revert()
    }
  }, [activeTab, index, ref])

  if (solution.sectionTitle || solution.sectionDescription) {
    return (
      <div ref={ref} className={styles.solutionExpanded + ' ' + solution.sectionStyle} key={index}>
        <div className={styles.solutionTextContainer}>
          <h2>{solution.sectionTitle}</h2>
          <PortableText value={solution.sectionDescription} components={customComponents} />
        </div>
      </div>
    )
  }
  return null
}

const ProductMultiSection = (props) => {
  const { data, allThemes } = props
  const customComponents = {
    marks: {
      footnote: ({ children }) => (
        <span>
          <sup>{children}</sup>
        </span>
      ),
    },
  }
  const [activeTab, setActiveTab] = useState(0)
  const isMobile = useMediaQuery('(max-width: 1000px)')

  //This forEach merges each solution theme with its respective data from the product page,
  //that way we only need to loop through one array of objects instead of jumping between them.
  //It also makes it less of a headache to exclude themes without data.

  allThemes.forEach((element) => {
    switch (element.solutionTitle) {
      case 'Clinical Intelligence':
        element.icon = ClinicalIntelligenceIcon
        element.sectionTitle = data.clinIntTitle
        element.sectionDescription = data._rawClinIntSection
        element.sectionStyle = styles.clinicalInt
        break
      case 'Value-Based Care':
        element.icon = ValueBasedCareIcon
        element.sectionTitle = data.valueTitle
        element.sectionDescription = data._rawValueSection
        element.sectionStyle = styles.valueBasedCare
        break
      case 'Margin Improvement':
        element.icon = MarginImprovementIcon
        element.sectionTitle = data.margImprovTitle
        element.sectionDescription = data._rawMargImprovSection
        element.sectionStyle = styles.marginImprov
        break
      default:
        element.icon = ClinicalIntelligenceIcon
        element.sectionTitle = data.clinIntTitle
        element.sectionDescription = data._rawClinIntSection
        element.sectionStyle = styles.clinicalInt
        break
    }
  })

  const handleHover = (index, action) => {
    if (action === 'enter' && activeTab !== index) {
      setActiveTab(index)
    }
  }
  // console.log(allThemes);

  return (
    <>
      <div className={styles.multiSectionContainer}>
        <div className={styles.sectionContainer}>
          {allThemes?.map((solution, index) => {
            if (solution.sectionTitle || solution.sectionDescription) {
              return (
                <React.Fragment key={index}>
                  <div
                    className={styles.solutionBlock}
                    style={{ backgroundColor: solution.settings.primaryColor }}
                    tabIndex={0}
                    role="button"
                    onMouseEnter={(e) => handleHover(index, 'enter')}
                    onMouseLeave={(e) => handleHover(index, 'leave')}
                    onFocus={(e) => handleHover(index, 'enter')}
                    onBlur={(e) => handleHover(index, 'leave')}
                  >
                    <div className={styles.imageContainer}>
                      <solution.icon />
                    </div>
                    <div className={styles.textContainer}>
                      {/* Was solution.solutionTitle, now solution.sectionTitle */}
                      <h2>{solution.sectionTitle}</h2>
                      {/* <h3>Solutions</h3> */}
                    </div>
                    <div
                      className={`${styles.downArrow} ${activeTab === index ? styles.showArrow : ''}`}
                      style={{ borderTopColor: solution.settings.primaryColor }}
                    ></div>
                  </div>
                  {/* text content appears below each tab on mobile */}
                  {isMobile && (
                    <TextContent
                      solution={solution}
                      index={index}
                      activeTab={activeTab}
                      customComponents={customComponents}
                    />
                  )}
                </React.Fragment>
              )
            }
            return null
          })}
        </div>
        <div className={styles.stacked}>
          {!isMobile &&
            allThemes?.map((solution, index) => {
              return (
                <React.Fragment key={index}>
                  <TextContent
                    solution={solution}
                    index={index}
                    activeTab={activeTab}
                    customComponents={customComponents}
                  />
                </React.Fragment>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default ProductMultiSection
