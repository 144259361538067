import React from "react";
import * as styles from './ProductContent.module.scss';
import Billboard from "../AppliedSciences/ASComponents/billboard/billboard";
import FeatureList from "../AppliedSciences/ASComponents/featureList/featureList";
import YellowBlock from "../AppliedSciences/ASComponents/yellowBlock/yellowBlock";
import ItemPopoverSection from "../AppliedSciences/ASComponents/itemPopoverSection/itemPopoverSection";
import TeamList from "../AppliedSciences/ASComponents/teamList/teamList";

const ProductContent = (props) => {
    const { data, theme } = props;

    // let backgroundStyle = { backgroundColor: "#DA437E"};
    // if(theme) {
    //   backgroundStyle = { backgroundColor: theme.settings.primaryColor };
    // }
    return (
        <>
        <div className={styles.contentContainer}>
            {data.logo &&
                <div className={styles.logoContainer}>
                    <img src={data.logo.asset.url} alt={data.altLogoText || 'Product Logo'} />
                </div>
            }
            {data.blocks && 
                <div className={styles.sectionContainer}>
                    {data.blocks.map((block, index) => (
                        <div key={index}>
                        {block._type==="billboard" && block.showElement && <Billboard key={index} data={block} themeContext={theme.solutionTitle} />}
                        {block._type==="featureList" && block.showElement && <FeatureList key={index} data={block} themeContext={theme.solutionTitle}/>}
                        {block._type==="yellowBlock" && block.showElement && <YellowBlock key={index} data={{...block}} themeContext={theme.solutionTitle}/>}
                        {block._type==="itemPopoverSection" && block.showElement && <ItemPopoverSection key={index} data={block} themeContext={theme.solutionTitle}/>}
                        {block._type==="teamList" && block.showElement && <TeamList key={index} data={block} teamList={data.teamMembers} themeContext={theme.solutionTitle}/>}
                    </div>
                    ))}
                </div>
            }
        </div>
        </>
    )
}

export default ProductContent